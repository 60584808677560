.account-list-root {
  width: 100%;
}

.account-list {
  margin-left: -12px;
  margin-right: -12px;
}

.account-list ul.react-multi-carousel-track > li {
  padding-left: 12px;
  padding-right: 12px;
}

.account-list .react-multi-carousel-item {
  padding-bottom: 8px;
}

.account-list .react-multi-carousel-item > div {
  height: 100%;
}

.account-list .react-multi-carousel-item > div > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-expired-acct {
  margin-top: 9px;
  margin-bottom: 9px;
}
