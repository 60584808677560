.nameOnCard {
  margin-bottom: calc(1rem - 10px) !important;
  margin-left: 10px !important;
  height: calc(2.25rem + 2px) !important;
  border-radius: 0.25rem !important;
  border: 1px solid #939393 !important;
  width: 96% !important;
}

.nameOnCardLabel {
  margin-bottom: 0.5rem !important;
  margin-left: 10px !important;
  line-height: 1.5 !important;
  font-size: 16px !important;
  font-family: Arial, sans-serif !important;
}
