.trans-table .ant-table-footer {
  display: none;
}

.print-transactions .ant-table-footer {
  display: none;
}

.print-transactions img {
  height: var(--font-size-large);
}
