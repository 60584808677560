.item {
  /* TODO: review spacing variables */
  padding: 20px !important;
}

.item > div {
  align-items: center;
}

.filterAccountId {
  font-size: var(--font-size-base);
  color: #7d89a1;
  padding-left: 10px;
}
