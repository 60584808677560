.languageSelectContainer {
  display: flex;
  justify-content: space-between;
  align-content: center;

  position: fixed;
  right: 12px;
  top: 12px;
}

.languageSelectorDropdown {
  width: 170px;
  position: fixed !important;
  top: 12px;
  right: 12px;
}

.currentLanguage {
  width: 25%;
  justify-content: space-between;
  align-content: center;
  display: flex;
  gap: 2px;

  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  flex-wrap: wrap;

  margin-left: 6px !important;
}

.changeLanguage {
  color: #2f54eb;
  font-size: 16px;
}

.languageOption {
  display: flex;
  align-items: center !important;
  font-size: 16px;
}

.langIcon {
  width: 22px;
  height: 22px;
  margin-left: 6px;
}

.signupButton {
  width: 47% !important;
  line-height: unset !important;
}

.emailText {
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 12px;
}

.editButton {
  cursor: pointer;
  color: #2f54eb;
}
