.personalCard {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.name {
  flex-direction: column;
  padding-left: 16px;
  display: flex;
  flex: 1;
  font-size: var(--font-size-large);
}

.name small {
  font-size: 14px;
}
