.mobileOption {
  font-size: 16px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-bottom: none !important;
  padding: 16px !important;
}

.activeMobileOption {
  background-color: var(--table-row-hover-bg) !important;
}

.select {
  width: 100%;
}
