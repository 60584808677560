.largeLine {
  line-height: 1.75;
}

.textPrimary {
  color: var(--primary-color) !important;
}

.textSuccess {
  color: var(--success-color) !important;
}

.textDisabled {
  color: var(--text-color-disabled) !important;
}

.textError {
  color: var(--danger-color) !important;
}

.normalFont {
  font-size: var(--font-size-base) !important;
}

.smallFont {
  font-size: var(--font-size-small) !important;
}

.mediumFont {
  font-size: var(--font-size-medium) !important;
}

.largeFont {
  font-size: var(--font-size-large) !important;
}
