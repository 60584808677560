.custom-antd-table {
  width: 100%;
}

.custom-antd-table .ant-empty-img-simple {
  width: 86px;
}

.custom-antd-table .ant-empty-normal .ant-empty-image {
  height: 65px !important;
}
