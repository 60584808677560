.custom-emtpy .ant-empty-img-simple {
  width: 86px;
  height: 65px;
}

.custom-emtpy .ant-empty-normal .ant-empty-image {
  height: auto !important;
}

.custom-emtpy .ant-empty-description {
  font-size: var(--font-size-medium);
}
