[data-reach-dialog-content] {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

[data-reach-dialog-content] > .content {
  flex: 1;
}

[data-reach-dialog-content] > .header {
  box-shadow: 0 1px 4px 0 #e7e9ee, 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
  display: flex;
}

[data-reach-dialog-content] > .header > span {
  padding: 0 16px;
}
