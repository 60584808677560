.mobile-carousel-pagination {
  justify-content: center;
  align-items: center;
  flex-direction: row;
  list-style: none;
  display: flex;
  width: 100%;
  padding: 0;
  margin-bottom: 2rem;
}

.mobile-carousel-pagination > a {
  display: inline-block;
  text-indent: -9999px;
  position: relative;
  margin: -1rem 3px;
  overflow: hidden;
  padding: 1rem 0;
}

.mobile-carousel-pagination > a > span {
  transition: all 0.5s ease-in-out;
  background: #ccc;
  display: block;
  height: 4px;
  width: 42px;
}

.mobile-carousel-pagination > a[data-active="true"] > span {
  background-color: var(--primary-color);
  border-radius: 2px;
}
