.ant-form-item-label > label {
  height: auto !important;
}

.ant-form-item-label label {
  font-weight: bold;
  font-size: 14px !important;
  margin-bottom: 2px !important;
  line-height: 1.5;
}

.ant-input {
  font-size: var(--font-size-medium) !important;
  padding: 14px !important;
}

.ant-input-affix-wrapper {
  padding: 0 14px !important;
}

.ant-input-affix-wrapper input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
