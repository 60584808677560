.badge-icon {
  position: relative;
  left: 5px;
  height: 18px;
  width: 25px;
}

.badge-text {
  margin-left: 20px;
}

.badge-button {
  align-items: center;
  align-content: center;
  align-self: center;
  height: 55px;
  width: 240px;
  border-radius: 2px;
  background-color: #597ef7;
}

.square {
  margin-top: -15px;
  margin-left: -10px;
  opacity: 0.2;
  height: 101.48px;
  width: 116.95px;
}

.circle {
  position: absolute;
  right: -25px;
  opacity: 0.2;
  height: 150px;
  width: 120px;
}

.rectangle-box {
  position: relative;
  left: 10px;
  align-items: center !important;
  box-sizing: border-box;
  height: 422px;
  width: 357px;
  border: 1px solid #e7e9ee;
  border-radius: 16px;
  background: linear-gradient(325.75deg, #b8eafc 0%, #4777ff 60%);
  box-shadow: 0 1px 4px 0 #e7e9ee, 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.rectangle-box-mobile {
  position: absolute;
  left: 5%;
  right: 5%;
  align-items: center !important;
  box-sizing: border-box;
  height: 422px;
  width: 90%;
  border: 1px solid #e7e9ee;
  border-radius: 16px;
  background: linear-gradient(325.75deg, #b8eafc 0%, #4777ff 60%);
  box-shadow: 0 1px 4px 0 #e7e9ee, 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.employee-id {
  position: relative;
  top: 20px;
  margin: 20px;
  padding: 0px;
  height: 20px;
  width: 161px;
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 21px;
}

.name {
  position: relative;
  top: 30px;
  margin: 20px;
  height: 24px;
  min-width: 251px;
  width: 251px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.rectangle-barcode-ve {
  position: relative;
  top: 120px;
  left: 5%;
  vertical-align: middle;
  box-sizing: border-box;
  height: 141px;
  width: 90%;
  border: 1px solid #e7e9ee;
  border-radius: 16px;
  background-color: #ffffff;
}

.rectangle-barcode {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: 120px;
  left: 5%;
  border-radius: 16px;
  background-color: #ffffff;
  width: 90%;
  padding: 5px 0px;
}

.barcode {
  box-sizing: border-box;
  background-color: #ffffff;
}

.barcode-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  column-gap: 5px;
}

.eye-icon {
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 16px;
  height: 16px;
}
