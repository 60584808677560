label.radio-input {
  flex-direction: row;
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: var(--font-size-medium);
  margin: -16px;
}

label.radio-input > div {
  min-height: var(--font-size-medium);
  min-width: var(--font-size-medium);
  position: relative;
  display: flex;
}

label.radio-input input {
  width: var(--font-size-medium);
  height: var(--font-size-medium);
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

label.radio-input .checkmark {
  border: 1px solid var(--text-color-disabled);
  width: var(--font-size-medium);
  height: var(--font-size-medium);
  position: absolute;
  border-radius: 50%;
  padding: 4px;
  left: 0;
  top: 0;
}

label.radio-input input ~ .checkmark::before {
  border-radius: 50%;
  position: absolute;
  display: block;
  content: "";
  bottom: 20%;
  right: 20%;
  left: 22%;
  top: 20%;
}

label.radio-input input:checked ~ .checkmark::before {
  background-color: var(--primary-color);
}

label.radio-input input:focus ~ .checkmark {
  box-shadow: 0 0 5px var(--primary-color);
}

label.radio-input .label {
  margin-left: var(--font-size-large);
  font-size: var(--font-size-base);
  display: inline-block;
  line-height: 1;
}
