.result-error .ant-result-icon > .anticon {
  font-size: 48px !important;
  color: var(--secondary-color-dark) !important;
}

.result-error .ant-result-title {
  margin-bottom: 8px;
}

.result-error .ant-result-extra {
  margin-top: 8px;
}
