.am-drawer .am-drawer-sidebar > * {
  z-index: 1000;
}

.ant-drawer-content-wrapper {
  width: 75vw !important;
  min-height: 100% !important;
  height: 100% !important;

  position: fixed !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-body a[data-active="true"] {
  background-color: var(--disabled-color-light) !important;
}

.languageSelectContainer {
  margin: 0 12px;

  display: flex;
  justify-content: space-between;
  align-content: center;
}

.currentLanguage {
  width: 25%;
  justify-content: space-between;
  align-content: center;
  display: flex;
  gap: 2px;

  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  flex-wrap: wrap;
}

.changeLanguage {
  color: #2f54eb;
  font-size: 16px;
}
