.authLayout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10rem;

  overflow: auto;
}

.content {
  max-width: 400px;
  width: 100%;
  padding-bottom: 4rem;
}

.content > img {
  max-height: 64px;
  max-width: 100%;
  margin-bottom: 4rem;
}

.withLogo {
  padding-top: 6rem;
}

.footer {
  width: 100%;
  margin-top: auto;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.videoLink {
  background-color: var(--primary-color-light);
}

.help {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;

  margin-bottom: 50px;
}
.element {
  margin: 8px;

  color: var(--text-color);
  font-size: var(--font-size-base);
}

.contactInfo {
  color: #464d5d;
}

@media (max-width: 420px) {
  .content {
    padding: 24px;
  }

  .authLayout {
    padding-top: 72px;
  }
}
