.toggle-icon {
  transition: 0.25s ease-in-out;
  position: relative;
  display: block;
  transform-origin: center center;
}

.toggle-icon.open {
  transform: rotate3d(1, 0, 0, 180deg);
}

.button-full-name {
  max-width: 20vw;
}

.ant-popover-inner-content a {
  color: var(--text-color) !important;
}

.ant-popover-inner-content a:hover {
  background-color: var(--primary-color-light);
}

.sidebar-header {
  margin: 1.5rem;
}

.sidebar-button {
  padding: 1rem;
}

/*nothing with screen size bigger than 650px*/
@media (max-height: 610px) {
  .sidebar-header {
    margin: 0.75rem;
  }

  .sidebar-button {
    padding: 0.8rem;
  }
}
