.avatar {
  width: 100%;
  font-size: 48px;
}

.largeSpace > div {
  padding: 40px !important;
}

.noSpace > div {
  padding: 0 !important;
}

.normalRadius {
  border-radius: 6px !important;
  overflow: hidden !important;
}

.noRadius {
  border-radius: 0 !important;
}

.elevated {
  box-shadow: 0 1px 4px 0 #e7e9ee, 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.smallPadding > div {
  padding: 10px !important;
}
