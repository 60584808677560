.modal {
  border-radius: 16px;
}

.large {
  width: 765px !important;
}

.normal {
  width: 425px !important;
}
