.layout {
  max-width: 100%;
}

.languageOption {
  display: flex;
  align-items: center !important;
  font-size: 16px;
}

.langIcon {
  width: 22px;
  height: 22px;
  margin-left: 6px;
}

.content {
  margin-top: 96px;
  margin-bottom: 32px;
}

.menuItem {
  padding-left: 12px !important;
  padding-right: 12px !important;
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.header {
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  overflow-y: hidden;
}

@media (max-width: 420px) {
  .header {
    padding: 0 !important;
    display: flex;
  }
}
