.btn {
  font-weight: bold;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 16px !important;
  line-height: 1 !important;
}

.primary:hover {
  background-color: var(--primary-color-dark) !important;
}

.secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.secondary:hover {
  background-color: var(--secondary-color-dark) !important;
  border-color: var(--secondary-color-dark) !important;
}

.outline:hover {
  background-color: rgba(249, 240, 255, 0.5) !important;
  border-color: rgba(249, 240, 255, 0.5) !important;
  border-color: var(--primary-color) !important;
}

.danger {
  background-color: var(--danger-color) !important;
  border-color: var(--danger-color) !important;
}

.danger:hover {
  background-color: var(--danger-color-dark) !important;
  border-color: var(--danger-color-dark) !important;
}

.disabled,
.disabled:hover {
  background: var(--secondary-light-color) !important;
  border-color: var(--secondary-color-dark) !important;
  color: var(--text-color-disabled);
}

.text {
  color: var(--text-color) !important;
}

.noPadding {
  padding: 0 !important;
  height: auto !important;
}
