.root {
  background-color: var(--secondary-light-color) !important;
  text-align: center;
  padding: 16px 32px;
  position: relative;
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

button.root {
  cursor: pointer;
}

.arrow {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -8px;
  color: var(--text-color-disabled) !important;
}
