.pop-no-arrow .ant-popover-arrow {
  display: none;
}

.pop-large {
  border-radius: 1rem;
}

.ant-popover-placement-bottom.pop-no-arrow,
.ant-popover-placement-bottomLeft.pop-no-arrow,
.ant-popover-placement-bottomRight.pop-no-arrow {
  padding-top: 5px !important;
}

.ant-popover-inner {
  min-width: 270px;
  overflow: hidden;
}

.ant-popover-inner .ant-popover-inner-content {
  padding: 0 !important;
}

.pop-large .ant-popover-inner {
  border-radius: var(--font-size-medium) !important;
  min-width: 320px;
}

.pop-large .ant-popover-inner > * {
  padding: 0 !important;
}

.pop-pos-fixed {
  position: fixed !important;
}
