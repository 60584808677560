.ant-modal.max-height .ant-modal-body {
  max-height: 520px;
  overflow-y: auto;
}

.ant-modal.waiver .ant-modal-body {
  height: 520px;
  overflow-y: hidden;
  padding: 0 !important;
}

@media (max-height: 670px) {
  .ant-modal.waiver .ant-modal-body {
    height: 400px;
  }
}

@media (max-height: 550px) {
  .ant-modal.waiver .ant-modal-body {
    height: 360px;
  }
}

@media (max-height: 520px) {
  .ant-modal.waiver .ant-modal-body {
    height: 340px;
  }
}

@media (max-height: 490px) {
  .ant-modal.waiver .ant-modal-body {
    height: 320px;
  }
}

@media (max-height: 450px) {
  .ant-modal.waiver .ant-modal-body {
    height: 280px;
  }
}

@media (max-height: 410px) {
  .ant-modal.waiver .ant-modal-body {
    height: 250px;
  }
}

@media (max-height: 385px) {
  .ant-modal.waiver .ant-modal-body {
    height: 200px;
  }
}
