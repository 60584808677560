.avatar {
  color: var(--link-color);
  justify-content: center;
  background: var(--link-color-light);
  display: inline-flex;
  align-items: center;
  border-radius: 100%;
}

.small {
  font-size: 20px;
  height: 32px;
  width: 32px;
  min-width: 32px;
}

.medium {
  font-size: 24px;
  height: 48px;
  width: 48px;
  min-width: 48px;
}

.large {
  font-size: 32px;
  height: 80px;
  width: 80px;
  min-width: 80px;
}
