.receipt-container {
  color: var(--text-color-disabled);
  max-width: 100%;
  margin: 0;
}

.ve-receipt-container {
  margin: 10px;
}

@media print {
  .receipt-container {
    display: block;
    margin: 4rem auto;
    border-width: 0 !important;
    font-size: 10px !important;
  }
}
