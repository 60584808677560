.loaderContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.containerBlur {
  /*Add the blur effect*/
  filter: blur(8px);
  -webkit-filter: blur(8px);

  position: relative;

  pointer-events: none;
}

.loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;
}
