.tag {
  border: 0 solid var(--primary-color);
  background: var(--primary-color-light);
  font-size: var(--font-size-base);
  display: inline-block;
  align-items: center;
  border-radius: 3px;
  padding: 4px 10px;
  line-height: 1.5;
  white-space: nowrap;
}

.tag-add {
  border-style: dashed;
  border-color: var(--secondary-color-dark);
  color: var(--text-color);
  background-color: transparent;
}

.tag-primary {
  border-color: var(--primary-color);
  background: var(--primary-color-light);
  color: var(--primary-color);
}

.tag-secondary {
  border-color: var(--secondary-color-dark);
  background-color: var(--secondary-color);
}

.tag-success {
  border-color: var(--success-color);
  background: var(--success-color-light);
}

.tag-warning {
  border-color: var(--warning-color);
  background: var(--warning-color-light);
}

.tag-danger {
  border-color: var(--danger-color);
  background-color: var(--danger-color-light);
}

.tag-large {
  font-size: var(--font-size-large);
  border-radius: 6px;
}

.tag-medium {
  font-size: var(--font-size-medium);
  border-radius: 4px;
  padding: 4px 16px;
}

.tag-small {
  font-size: 12px !important;
  border-radius: 2px !important;
  padding: 2px 8px;
}

.tag-bordered {
  border-width: 1px;
  margin: -1px;
}
