.typeIcon {
  font-size: 22px;
  padding: 0 6px;
  display: block;
  line-height: 0;
}

.typeIcon > span {
  line-height: 0;
  margin: 0;
  padding: 0;
}

.typeCol {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.greenText {
  color: var(--success-color);
}

.filterAccountId {
  font-size: var(--font-size-base);
  color: #7d89a1;
  padding-left: 10px;
}

.buttonHeight {
  height: 40px !important;
}
